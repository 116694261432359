var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "50",
      "height": "40",
      "fill": "none"
    }
  }, [_c('rect', {
    attrs: {
      "width": "50",
      "height": "40",
      "fill": "#D13557",
      "rx": "10"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M9.572 29h4.252v-9.092c.246-.565.574-1.007.985-1.326.41-.319.897-.479 1.463-.479.656 0 1.152.187 1.49.561.337.374.506.975.506 1.805V29h4.265v-9.092c.246-.556.579-.993.998-1.312.42-.328.912-.492 1.477-.492.647.009 1.135.2 1.463.574.337.364.506.952.506 1.763V29h4.265v-8.654c0-1.96-.424-3.359-1.271-4.198-.839-.847-1.95-1.271-3.336-1.271a5.94 5.94 0 0 0-2.694.629 4.729 4.729 0 0 0-1.968 1.79h-.041a3.46 3.46 0 0 0-1.545-1.804c-.72-.41-1.568-.615-2.543-.615-.857 0-1.64.178-2.352.533a4.269 4.269 0 0 0-1.709 1.477h-.068l-.055-1.71H9.572V29Zm24.993-2.01c0 .63.214 1.172.642 1.627.428.447 1.04.67 1.832.67.802 0 1.413-.223 1.832-.67.428-.446.643-.989.643-1.627s-.215-1.18-.643-1.627c-.428-.446-1.039-.67-1.832-.67-.802 0-1.417.224-1.846.67-.419.447-.628.99-.628 1.627Zm0-10.185c0 .638.21 1.18.628 1.627.429.446 1.044.67 1.846.67.793 0 1.404-.224 1.832-.67.428-.447.643-.99.643-1.627 0-.63-.215-1.167-.643-1.614-.42-.446-1.03-.67-1.832-.67-.793 0-1.404.224-1.832.67a2.247 2.247 0 0 0-.642 1.614Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }